import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Projects.css'
import { Carousel } from 'react-responsive-carousel';

import { projectItems, otherProjectItems } from "../../data/projectsData";

import aiMockup from '../../assets/images/ai-mockup.png'
import muviDb from '../../assets/images/muviDB.png'

import { FaGithub, FaExternalLinkAlt, FaColumns} from 'react-icons/fa'

function Projects() {

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) 
        newWindow.opener = null
        console.log(url)
      }

  return (
      <>
    <section id="projects" className='projects-section'>
        <h3 className='rg_section-heading'>Latest Projects _</h3>
        <div className='carousel-container'>
            <Carousel 
            className='carousel' 
            showStatus={false}
            swipeable
            >
                {projectItems.map(project => (
                    <div key={`main_project_${project.title}`} className='carousel-item'>
                    <img src={project.image} alt='project-img'/>
                        <div className='project-details-container'>
                            <div className="project-details">
                                <h1 className="project-title">{project.title}</h1>
                                <p className="project-description">{project.description}</p>
                                <div className="project-tech-container">
                                    {project.stack.map(stackItem => (
                                        <p key={`stack_${stackItem}`} className="project-tech">{stackItem}</p>
                                    ))}   
                                </div>
                                <div className="project-links">
                                    <a href={project.gitSrc} target="_blank" rel='noreferrer'><FaGithub /></a>
                                    <a href={project.webSrc} target="_blank" rel='noreferrer'><FaExternalLinkAlt /></a>
                                </div>
                            </div>
                        </div>
                </div>
                ))}
            </Carousel>
        </div>

    {/*Featured projects on mobile only */}
    <div className="mobile-view">
    {projectItems.map(project => (
                    <div key={`main_project_${project.title}`} className='mobile-project-item'>
                        <img src={project.image} alt='project-img'/>
                            <div className='mobile-project-details-container'>
                                <div className="mobile-project-details">
                                    <h1 className="mobile-project-title">{project.title}</h1>
                                    <p className="mobile-project-description">{project.description}</p>
                                    <div className="mobile-project-tech-container">
                                        {project.stack.map(stackItem => (
                                            <p key={`stack_${stackItem}`} className="mobile-project-tech">{stackItem}</p>
                                        ))}   
                                    </div>
                                    <div className="mobile-project-links">
                                        <a href={project.gitSrc} target="_blank" rel='noreferrer'><FaGithub /></a>
                                        <a href={project.webSrc} target="_blank" rel='noreferrer'><FaExternalLinkAlt /></a>
                                    </div>
                                </div>
                            </div>
                    </div>
                ))}
            </div>
     </section>

        {/* Other Projects */}
      <section className="other-projects-container">
        <h3 className='rg_section-heading'>Other Projects _</h3>                           
        <div className="other-projects-grid">

            {otherProjectItems.map(project => (
                <div key={`other_project_${project.title}`} onClick={() => openInNewTab(project.webSrc)} className="other-project-card">
                {/* <a href={project.webSrc} target="_blank" rel='noreferrer'> */}
                    <div className="other-project-item-header">
                        <span className="other-project-item-icon"></span>
                            <div className="other-project-links">
                                <a href={project.gitSrc} target="_blank" rel='noreferrer'><FaGithub /></a>
                                <a href={project.webSrc} target="_blank" rel='noreferrer'><FaExternalLinkAlt /></a>           
                            </div>       
                    </div>
                    
                        <h3 className="other-project-title">{project.title}</h3>
                        <p className="other-project-description">{project.description}</p>
                    <div className="other-project-tech">
                        {project.stack.map(item => (
                            <p key={`${item}`}>{item}</p>
                        ))}                                                                
                    </div>
                    {/* </a> */}
                </div>
            ))}
        </div>
    </section>
    </>
  )
}
export default Projects