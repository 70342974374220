import muviDB from '../assets/images/muviDB.png'
import aiMockup from '../assets/images/ai-mockup.png'
import sicilyMemoryGame from '../assets/images/sicily-memory-game.png'
import tripTraveller from '../assets/images/trip-traveller.jpg'
import nftlounge from '../assets/images/nftlounge.jpg'


export const projectItems = [
    {
        title: 'NFT Lounge',
        description: 'A React Native app displaying some of the latest NFTs on the market',
        stack: ["React Native", "Expo"],
        image: nftlounge,
        gitSrc: 'https://github.com/robertgarofalo/nft-market-app',
        webSrc: 'https://expo.dev/@robbyg1987/nft-market-place?serviceType=classic&distribution=expo-go/',
    },
    {
        title: 'Trip Traveller',
        description: 'A MERN web app where you can add your ideal travel destinations to your bucket list',
        stack: ["React", "Node.js", "MongoDB", "Express.js", "API"],
        image: tripTraveller,
        gitSrc: 'https://github.com/robertgarofalo/trip-traveller',
        webSrc: 'https://triptraveller.herokuapp.com/',
    },
    {
        title: 'muviDB',
        description: 'A Netflix style movie database showcasing the latest movies currently available',
        stack: ["React", "Firebase", "API"],
        image: muviDB,
        gitSrc: 'https://github.com/robertgarofalo/muvi-db',
        webSrc: 'https://robbygcodes-muvidb.netlify.app/',
    },
    {
        title: 'Open AI',
        description: "A mockup introductory website on what is Artificial Intelligence and the benefits of it",
        stack: ["React"],
        image: aiMockup,
        gitSrc: 'https://github.com/robertgarofalo/ai-mockup',
        webSrc: 'https://ai-mockup.netlify.app/',
    },
    {
        title: 'Sicily Memory Game',
        description: "A memory card game - Sicilian style",
        stack: ["React"],
        image: sicilyMemoryGame,
        gitSrc: 'https://github.com/robertgarofalo/sicilia-memory-game',
        webSrc: 'https://sicilian-memory-game.netlify.app/',
    },
]



export const otherProjectItems = [
    {
        title: 'Daily Dashboard',
        description: 'A dashboard showing you the latest news, weather and messages.',
        stack: ["React", "News / Weather API"],
        gitSrc: "https://github.com/robertgarofalo/daily-dashboard",
        webSrc: "https://robbygcodes-daily-dashboard.netlify.app/"
    },
    {
        title: 'TravelGram Sicily',
        description: 'Instagram inspired feed showing images of the best island on earth',
        stack: ["React", "Firebase"],
        gitSrc: "https://github.com/robertgarofalo/travelgram-Sicily-project-react-firebase",
        webSrc: "https://travelgram-sicily.netlify.app/"
    },
    // {
    //     title: 'Architecture 2.0',
    //     description: 'A demo landing page for an architecture firm with a twist',
    //     stack: ["CSS", "Javascript"],
    //     gitSrc: "https://github.com/robertgarofalo/small-projects/tree/master/architecture-2-0",
    //     webSrc: "https://www.creativecrewstudios.com/architecture-2-0/index.html"
    // },
    {
        title: 'Square Root',
        description: 'Maths tutoring made fun!',
        stack: ['CSS', 'Javascript', 'PHP', 'Wordpress'],
        webSrc: 'https://www.squareroot.com.au/'
    },
    {
        title: 'Fight Strength Coach',
        description: 'Custom martial arts training programs - unleash the warrior within',
        stack: ['Wordpress', 'CSS'],
        webSrc: 'https://www.fightstrengthcoach.com/'
    },
    
    // {
    //     title: 'Apple Mockup',
    //     description: 'Official 2020 Apple website mockup - yes, MOCK-UP',
    //     stack: ['CSS'],
    //     webSrc: 'https://creativecrewstudios.com/portfolio/projects/apple-homepage/index.html',
    //     gitSrc: 'https://github.com/robertgarofalo/small-projects/tree/master/APPLE%20homepage'
    // },
    
    {
        title: 'Baby Cakes',
        description: 'Mock up sweets and candy business home page - sorry baby cakes but it\'s fake',
        stack: ['CSS'],
        webSrc: 'https://creativecrewstudios.com/portfolio/projects/hello-baby-cakes/index.html',
        gitSrc: 'https://github.com/robertgarofalo/small-projects/tree/master/Museum%20of%20Candy'
    },
   
    {
        title: 'Learn Sicilian',
        description: 'Official blog for the Learn Sicilian iOS and Android language app',
        stack: ['CSS', 'Wordpress'],
        webSrc: 'https://blog.learnsicilian.com/'
    },
    
    {
        title: 'Creative Crew Studios',
        description: 'Official homepage of the best Freelance Web development service on the planet',
        stack: ['CSS', 'Javascript', 'Wordpress'],
        webSrc: 'https://creativecrewstudios.com/'
    },
    {
        title: 'Match Maker',
        description: 'Match makeing app - think Tinder but leaner... and weirder',
        stack: ['CSS', 'Javascript', 'API'],
        webSrc: 'https://creativecrewstudios.com/portfolio/projects/match-maker/index.html',
        gitSrc: 'https://github.com/robertgarofalo/javascript-projects/tree/master/Match%20Maker',
    },
    {
        title: 'Reciplease',
        description: 'Choose from a list of hundreds of recipes.. please',
        stack: ['CSS', 'Javascript', 'Meals API'],
        webSrc: 'https://creativecrewstudios.com/portfolio/projects/Reciplease/index.html',
        gitSrc: 'https://github.com/robertgarofalo/javascript-projects/tree/master/Reciplease',
    },

]