import { FaGithub, FaInstagram, FaTwitter, FaLinkedinIn, FaRegEnvelope, FaWordpressSimple} from 'react-icons/fa'
import './SocialNav.css'

export const socialLinks = [
  { href: "https://www.github.com/robertgarofalo", icon: <FaGithub /> },
  { href: "https://www.linkedin.com/in/robertgarofalo/", icon: <FaLinkedinIn /> },
  // { href: "https://twitter.com/robbyg_dev", icon: <FaTwitter /> },
  { href: "https://www.robertgarofalo.com/", icon: <FaWordpressSimple /> },
  // { href: "https://www.instagram.com/robbyg_dev", icon: <FaInstagram /> },
  { href: "mailto:hello@robertgarofalo.com", icon: <FaRegEnvelope /> },
]

function SocialNav({flexDirection = "column"}) {
  return (
      <div className='social-container'>
        <div className='social-nav' style={{flexDirection,}}>
            <ul>
                {socialLinks.map((social, index) => (
                  <li key={index}><a href={social.href} target="_blank" rel='noreferrer'>{social.icon}</a></li>
                ))}
            </ul>  
        </div>
      </div>
  )
}
export default SocialNav
