import { useState, useEffect } from 'react'

import { FaBars, FaRegWindowClose } from 'react-icons/fa'

import './Navbar.css'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import resume from '../assets/Resume.pdf'

//components
import { socialLinks } from './SocialNav'

// Scroll up to show navbar
function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection]);
  return scrollDirection;
};


function Navbar() {
const scrollDirection = useScrollDirection()
const [navIsOpen, setNavIsOpen] = useState(false)

const navbarMenuItems = ["About", "Skills", "Experience", "Projects", "Contact"]

const mobileMenuHandler = (navIsOpen) => {
  setNavIsOpen(!navIsOpen)
  if(navIsOpen === true) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'visible'
  }
}

  useEffect(() => {
    console.log(navIsOpen)
  },[navIsOpen])

  return (
    <>
    <div className={`navbar ${ scrollDirection === "down"? "hide" : "show"}`}>
      <div className={`navbar-container`}>
          <div className="logo">
              <AnchorLink href='#home'><p className='logo_link'>robert garofalo_</p></AnchorLink>
          </div>
          <div className="navbar-menu-items">
            {navbarMenuItems.map(item => (
              <AnchorLink key={item} href={`#${item.toLowerCase()}`}><p className='navbar-menu-link'>{item}</p></AnchorLink>
            ))}
            <a href={resume}><p className='rg_btn'>Resume</p></a>
          </div>
              <FaBars className='mobile-menu-button-open' onClick={() => mobileMenuHandler(navIsOpen)}/>
      </div>

    </div>
              {/* Mobile menu view */}
          <div className={`mobile-menu-view ${navIsOpen ? 'display' : 'hidden'}`}>
              <div className='mobile-menu-header'>
                  {/* <p>robbyg.dev _</p> */}
                  <p></p>
                  <FaRegWindowClose className='mobile-menu-button-close' onClick={() => mobileMenuHandler(navIsOpen)}/>
              </div>

              <div className='mobile-menu-items'>
                {navbarMenuItems.map(item => (
                  <AnchorLink key={item} href={`#${item.toLowerCase()}`} onClick={() => mobileMenuHandler(navIsOpen)}><p className='mobile-menu-link'>{item}</p></AnchorLink>
                ))}
                <a className='mobile-menu-resume-button' href={resume}><p className='rg_btn'>Resume</p></a>
              </div>

              <div className='mobile-menu-social'>
                  {socialLinks.map((social, index) => (
                    <li key={`social-icon-${index}`} className='mobile-menu-social-icon'><a href={social.href} target="_blank" rel='noreferrer'>{social.icon}</a></li>
                  ))}
              </div>

          </div>
    </>
  )
}
export default Navbar