import './Experience.css'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function Experience() {
  return (
      <div id="experience" className='experience-section'>
        <h3 className='rg_section-heading'>Experience _</h3>
          <Tabs selectedTabClassName='experience-selected-tab' className='experience-tab'>
            <TabList className='experience-tab-list'>
              <Tab>Creative Crew Studios</Tab>
              <Tab>Fiverr</Tab>
              <Tab>Courses</Tab>
            </TabList>

            <TabPanel className='experience-tab-panel'>
              <h3 className="title">Founder - <a href="https://www.creativecrewstudios.com" target="_blank" rel='noreferrer'>Creative Crew Studios</a></h3>
              <p className="date">Sept 2019 - present</p>
              <ul className='experience-tab-panel-list'>
                <li>  Built websites for clients  </li>
                <li>  Customized existing websites as per client's requirements</li>
                <li>  Maintained and assisted with server issues </li>
                <li>  Liased with clients in person or via Zoom/email</li>
              </ul>
            </TabPanel>
            <TabPanel className='experience-tab-panel'>
              <h3 className="title">Freelance developer @ <a href="https://www.fiverr.com/rsginc?public_mode=true" target="_blank" rel='noreferrer'>Fiverr</a></h3>
              <p className="date">May 2019 - present</p>
              <ul className='experience-tab-panel-list'>
                <li>  Built websites for clients  </li>
                <li>  Customized existing websites as per client's requirements</li>
                <li>  Maintained and assisted with server issues </li>
              </ul>
            </TabPanel>
            <TabPanel className='experience-tab-panel'>
              {/* <h3>Freelancer dev @ <a href="https://www.creativecrewstudios.com" target="_blank" rel='noreferrer'>Creative Crew Studios</a></h3> */}
              <h3 className="title">Completed Course Certifications</h3>
              <p className="date">Sept 2017 - present</p>
              <ul className='experience-tab-panel-list'>
                <li>  <a href="https://certificates.cs50.io/e6213db8-ee18-464f-bc60-2f4eca17f359.png?size=letter" target="_blank" rel='noreferrer'>CS50 Harvard - CS50x Computer Science</a></li>
                <li>  <a href="https://www.codecademy.com/profiles/Robbyg1987/certificates/666120308fc207a66d8afaa0dcf4ee19" target="_blank" rel='noreferrer'>Codecademy - React JS</a></li>
                <li>  <a href="https://www.codecademy.com/profiles/Robbyg1987/certificates/705dcb15de0da4dd9d9fc4f3274b430e" target="_blank" rel='noreferrer'>Codecademy - Javascript ES6</a></li>
                <li>  <a href="https://www.codecademy.com/profiles/Robbyg1987/certificates/240305d50b925c17868f1ac7a21a3261" target="_blank" rel='noreferrer'>Codecademy - Node JS</a></li>
                <li>  <a href="https://www.codecademy.com/profiles/Robbyg1987/certificates/0595479d03627a8cb816b069000e4d06" target="_blank" rel='noreferrer'>Codecademy - Bootstrap</a></li>
                <li>  <a href="https://coursera.org/verify/LW6ZVARK5AW9" target="_blank" rel='noreferrer'>Google - IT Support Professional</a></li>
              </ul>
            </TabPanel>
            

        </Tabs>
      </div>
    
  )
}
export default Experience
