import SocialNav from '../../components/SocialNav'
import './Hero.css'


function Hero() {
  return (
    <>
    <section className="hero-section">
        <p className='intro'>Hey there, I'm</p>
        <h1 className='name'>Robert Garofalo_</h1>
        <p className='description'>I'm a web developer and freelancer from Melbourne, Australia and I love building stuff for the web _ <br />
        </p>
        <a href="mailto:hello@robertgarofalo.com"><button className='rg_btn'>Contact Me</button></a>
    </section>
    <SocialNav />
    </>
  )
}
export default Hero
