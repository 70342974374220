import './Skills.css'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function Skills() {
  return (
      <div id="skills" className='skills-section'>
        <h3 className='rg_section-heading'>Skills _</h3>
          <Tabs selectedTabClassName='selected-tab' className='skills-tab'>
            <TabList className='skills-tab-list'>
              <Tab>Technology</Tab>
              <Tab>Tools</Tab>
              <Tab>Other</Tab>
              {/* <Tab>In Progress</Tab> */}

            </TabList>

            <TabPanel className='tab-panel'>
              <ul className='tab-list'>
                <li>  Javascript ES6+</li>
                <li>  React js</li>
                <li>  React Native</li>
                <li>  Python</li>
                <li>  Flask / Jinja</li>
                <li>  Bootstrap</li>
                <li>  Redux</li>
                <li>  Node.js</li>
                <li>  Express.js</li>
                <li>  SQLite</li>
                <li>  MongoDB</li>
                <li>  Firebase</li>

              </ul>
            </TabPanel>
            <TabPanel className='tab-panel'>
            <ul className='tab-list'>
              <li>  Git</li>
              <li>  VS Code</li>
              <li>  Netlify</li>
              <li>  Heroku</li>
              <li>  NPM</li>
              <li>  Wordpress</li>
              {/* <li>  Slack</li> */}
              </ul>
            </TabPanel>
            <TabPanel>
            <ul className='tab-list'>
              <li>  Figma</li>
              <li>  Photoshop</li>
              <li>  Premiere Pro</li>
              <li>  After Effects</li>
              <li>  Audacity</li>
              <li>  Canva</li>
            </ul>
            </TabPanel>
            {/* <TabPanel>
            <ul className='tab-list'>
              <li>  Vue</li>
              <li>  MySQL</li>
            </ul>
            </TabPanel> */}
        </Tabs>
      </div>
    
  )
}
export default Skills
