import './Contact.css'


const Contact = () => {
  return (
    <section id="contact" className='contact-container'>
        <h3 className='rg_section-heading'>Contact Me_</h3>
        <p className='rg_section-paragraph'>My inbox is always open so feel free to send me an email and I'll get back to you as soon as I can. </p>
        <a href="mailto:hello@robertgarofalo.com"><button className='rg_btn contact-btn'>Get in touch</button></a>
    </section>
  )
}
export default Contact