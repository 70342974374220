import './App.css';

// components
import Navbar from './components/Navbar'

// pages section
import Hero from './pages/home/Hero'
import About from './pages/home/About'
import Skills from './pages/home/Skills';
import Experience from './pages/home/Experience';
import Projects from './pages/home/Projects';
import Contact from './pages/home/Contact';
import Footer from './components/Footer'

function App() {
  return (
    <div id="home" className="App">
      <Navbar />
      <div className='rg_main-container'>
        <Hero />
        <About />
        <Skills />
        <Experience />
        <Projects />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;


/*

Color Pallet:
BG - 0a192f
site accent color - blue?
text - white and grey


--- Structure ---

NAVBAR

1. Main section - Introduction
CTA?-------------------------------- DONE

2. About me section -------------------------------- DONE
things to mention - when i started coding, aim, i like travelling, dogs etc

3. Skills -------------------------------- DONE
 - programming languages
 - frameworks
 - other tools

4. Work Experience - 
Freelancing - Creative Crew Studios
Freelancing - Fiverr

4. Things I've built

5. Other noteworthy projects

5. Contact me

FOOTER


-------
other to do

create PDF

- BUCKET LIST?
*/