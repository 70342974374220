import './About.css'

//assets
import placeholderImg from '../../assets/images/placeholder-image.png'
import robert from '../../assets/images/robert.png'

function About() {
  return (
    <section id="about" className='about-section'>
        
        <div className='text-container'>
            <h3 className='rg_section-heading'>About Me _</h3>
            <p className='rg_section-paragraph'> I've always had a passion for technology and I've been very active with computers from an early age. It wasn't until 2017 that I started learning to code as a hobby which then led me on my journey to becoming a self taught web developer. </p>

            <p className='rg_section-paragraph'>Fast forward to now and I've learnt a lot. I've been very fortunate to build websites and projects not only for myself, but for clients either through freelance platforms like <span className='span-text'><a href="https://www.fiverr.com/rsginc?public_mode=true" target="_blank" rel='noreferrer'>Fiverr</a></span> or even privately through my own brand <span className='span-text'><a href="https://www.creativecrewstudios.com" target="_blank" rel='noreferrer'>Creative Crew Studios</a></span>.</p>

            <p className='rg_section-paragraph'>I'm a <span className='span-text'>curious person</span> and <span className='span-text'>I love learning</span>. It's a big passion of mine. I also love travelling, martial arts, comedy films and food! </p>
        </div>
        
        <div className='img-container'>
            <img src={robert} />
            <div className='border'></div>
        </div>

    </section>
  )
}
export default About